@media only screen and (min-width: 1920px) {
  .GetInCOntainer {
    margin-top: 410px;
    margin-bottom: 410px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px) {
  .MaximumBlackLine {
    height: 5px;
  }
  .GetInCOntainer {
    margin-top: 310px;
    margin-bottom: 310px;
  }

  .MaximumOfHeaderStyle {
    font-size: 100px;
  }
  .DescriptionBigTextStyle {
    font-size: 25px;
  }
  /* .DescriptionNormalTextStyle{
    font-size: 16px;
  } */

  .footerSocialNetworks {
    width: 50%;
  }
  .footerContactsBoxes {
    flex-direction: column;
  }
  .footerDescription {
    width: 50%;
  }
  .NavigationMenuBigText {
    font-size: 70px;
  }
  /* .MaximumOfHeaderStyle {
    font-size: 120px;
  } */
  /* .MaximumOfHeaderStyleItalic {
    font-size: 120px;
  } */
  .centerLogoItemsStyle {
    width: 60px;
    height: 120px;
  }
  .centerLogoItems {
    width: 187px;
  }
  .BlockHeader {
    font-size: 40px;
  }
  .BlockHeaderItalic {
    font-size: 40px;
  }
  .worksTextDescription {
    font-size: 20px;
  }
  .DescriptionBigTextStyleWorks {
    font-size: 30px;
  }
  .GetInTouchBox {
    font-size: 120px;
  }
}

/* tablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .rightBoxHeader{
    font-size: 16px;
  }
  .MaximumBlockHeader{
    margin-top: 100px;
  }
  .MaximumBlockHeaderMain{
    margin-top: 0;
  }
  .aboutPageMarginBlock{
    height: 150px;
  }
  .worksMainContainersMarginTop {
    margin-top: 0;
  }
  /* .MaximumOfHeaderStyle,
  .MaximumOfHeaderStyleItalic {
    font-size: 100px;
  } */
  .GetInTouchLine {
    height: 2px;
  }

  .MaximumBlackLine {
    height: 5px;
  }
  .GetInCOntainer {
    margin-top: 260px;
    margin-bottom: 260px;
  }
  .worksMainContainers {
    margin-top: 100px;
  }
  .aboutTextBoxes {
    width: 100%;
  }
  .footerSocialNetworks {
    width: 50%;
  }
  .footerContactsBoxes {
    flex-direction: column;
  }
  .footerContactInfo {
    width: 100%;
  }
  .logoFooterBox,
  .footerDescription {
    width: 50%;
  }
  .NavigationMenuBigText {
    font-size: 50px;
  }
  .MaximumOfHeaderStyle {
    font-size: 60px;
  }
  .MaximumOfHeaderStyleItalic {
    font-size: 60px;
  }
  .BlockHeader {
    font-size: 24px;
  }
  .BlockHeaderItalic {
    font-size: 24px;
  }
  .centerLogoItemsStyle {
    width: 45px;
    height: 90px;
  }
  .centerLogoItems {
    width: 140px;
  }

  .BlockHeaderWorks {
    font-size: 50px;
  }
  .GetInTouchBox,
  .getInTouchWordIn {
    font-size: 100px;
  }
  .ReachTheMaximumGetInTouch {
    width: 60%;
    font-size: 30px;
  }
  .successMainContainer {
    margin-top: 150px;
  }
}
/* mobile */

@media only screen and (max-width: 767px) {
  .NavigationMenuBigText{
    letter-spacing: -1.5px;

  }
  .aboutPageMarginBlock{
  height: 100px;
}
  .MaxOutput {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 60px;
  }
  .OutputConteiner {
    gap: 32px;
    width: 75%;
    align-items: flex-start;

  }
  .OutputConteinerSize {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .BlockHeaderCase {
    font-size: 24px;
  }
  .rightTextBlockCase {
    width: 100%;
  }
  /* .TextBlockCase {
    box-sizing: border-box;
    padding: 10px;
  } */
  .CaseContainer {
    flex-direction: column-reverse;
    gap: 60px;
  }
  .CaseDescriptionBigTextStyle {
    font-size: 20px;
    width: 100%;
  }

  .valueTextBox {
    width: 75%;
    gap: 50px;
  }
  .valueContainer {
    margin-top: 100px;
  }
  .worksMainContainers {
    margin-top: 0;
  }
  .SecondAboutPageLeftText,
  .SecondAboutPageRightText {
    width: 100%;
  }
  .SecondAboutPageTextContainer {
    width: 75%;
  }
  .SecondAboutPageTextContainer {
    flex-direction: column;
  }
  .MaximumBlockHeader {
    margin-top: 100px;
  }
  .aboutTextBlockMain {
    margin-bottom: 100px;
  }
  .MaximumBlockHeaderTOMAX {
    margin: 100px 0;
  }
  /* .AboutPageTextBlockDescription {
    margin-bottom: 100px;
  } */
  .GetInTouchLine {
    height: 2px;
  }
  .MaximumBlackLine {
    height: 3px;
  }
  .GetInCOntainer {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  .logoMask {
    height: 32vh;
  }
  .AboutWord {
    margin-top: 100px;
  }
  .AboutDescription {
    margin-bottom: 24px;
  }
  .ReachTheMaximumGetInTouch {
    width: 70%;
  }
  .getInTouchWordTouch {
    width: 100%;
  }
  .MaximumBlackLine {
    margin-right: 10px;
  }
  .MaximumBlockHeaderLineBox {
    width: 100%;
  }
  .MaximumBlockHeaderBottomBox {
    flex-direction: column;
  }
  .mobileBurgerTextStyle {
    font-size: 30px;
  }
  .NavigationMenuBigText {
    font-size: 24px;
  }
  .DescriptionNormalTextStyle {
    font-size: 16px;
  }
  .BlockHeader {
    font-size: 24px;
  }
  .BlockHeaderItalic {
    font-size: 24px;
  }
  .MaximumOfHeaderStyle {
    font-size: 68px;
  }
  .MaximumOfHeaderStyleItalic {
    font-size: 68px;
  }
  .homeLogo {
    width: 50px;
    height: 25px;
  }

  .footerBlockMobile {
    display: block;
  }
  .footerBlock {
    display: none;
  }
  .Contacts {
    font-family: "ABCFavorit-Book";
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    margin: 60px;
  }
  .footerDescriptionMobile {
    line-height: 150%;
    margin-bottom: 30px;
    opacity: 0.5;
  }

  .footerMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    gap: 10px;
    color: white;
  }
  .footeMobileSocial {
    display: flex;
    gap: 8px;
    line-height: 150%;
    flex-direction: column;
    color: #fff;
  }
  .footerContactInfo {
    opacity: 0.5;
  }
  .footerContactId {
    opacity: 0.5;
  }
  .footerContactsMobile {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footerBlockMobile {
    display: flex;
    align-items: center;
    height: auto;
  }
  .successImgMobile {
    width: 15%;
    z-index: 1;
  }
  .shapeSucessMobile {
    transform: rotate(135deg);
    position: absolute;
    background-color: white;
    height: 75%;
    width: 25%;
  }
  .successMainContainer {
    height: auto;
  }

  .successBoxImgMobile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
  }
  .MaximumOfSuccess {
    margin-top: 10%;
  }
  .successBlock {
    height: auto;
  }
  .sucessTabDescription {
    width: 100%;
    margin-top: 10px;
  }
  .footerDescriptionMobile {
    padding: 10px;
  }
  .rightSuccessBox {
    width: 100%;
    padding: 10px;
  }
  .successMainContainer {
    margin-top: 100px;
  }
  .leftSuccessBox {
    display: none;
  }
  .MaximumOfSuccess {
    margin-bottom: 10vh;
  }

  .upperBoxGetInTouch {
    width: 75%;
  }
  .bottomBoxGetInTouch {
    flex-direction: column;
    align-items: center;
  }
  .MaximumOfServices {
    margin-bottom: 100px;
  }
  .rightServiceBox {
    width: 100%;
  }
  .leftServiceBox {
    display: none;
  }
  .worksBigBackgroundBlock,
  .worksBigBlockText {
    height: 100%;
  }
  .worksBigBackgroundBlock {
    margin-bottom: 75px;
  }
  .worksBigImg {
    height: 100%;
  }
  .worksBigBackgroundContainer {
    width: 100%;
    height: 100%;
  }
  .worksBigContainers {
    flex-direction: column;
  }

  .MaximumOFContainer {
    width: 100%;
  }
  .worksBoxes {
    margin-bottom: 75px;
    width: 100%;
  }

  .worksContainers {
    flex-direction: column;
    width: 100%;
  }
  .MaximumOfDesignWorks {
    justify-content: space-between;
    align-items: flex-start;
  }
  .aboutTextBlock {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-evenly;
  }
  .aboutTextBlock,
  .MaximumOfWorks,
  .rightServiceBox {
    box-sizing: border-box;
    padding: 10px;
  }
  .MaximumOfWorks {
    margin-top: 100px;
  }
  .aboutText,
  .aboutTextBoxes,
  .aboutWord {
    width: 100%;
  }
  .aboutWord {
    text-align: center;
  }
  #preloaderReach {
    width: 50%;
  }
  .MaximumOfDesignOfLine {
    width: 100%;
  }
  .homeLogobox,
  .burgerIcon {
    padding: 10px;
  }
  .rightBoxHeader {
    display: none;
  }
  .burgerIcon {
    display: block;
  }
  .header {
    background-color: transparent;
    justify-content: space-between;
  }
  .textBlockMaximumOfDesign {
    padding: 10px;
  }
  .centerLogoItemsStyle {
    width: 36px;
    height: 72px;
  }
  .centerLogoItems {
    width: 112px;
  }
  .BlockHeaderWorks {
    font-size: 50px;
  }
  .ReachTheMaximumGetInTouch {
    font-size: 20px;
  }
  .GetInTouchLine {
    height: 1px;
  }
  .preloaderline {
    height: 2px;
  }
  .wordReach {
    margin-right: 5px;
  }
  .BlockHeaderItalicTeam {
    font-size: 20px;
  }
  .marginBlockPortfolio {
    height: 0;
  }
  .MaximumBlockHeaderMain{
    margin-top: 0;
  }
  .mobileNormalText{
    font-size: 20px;
  }
  .box100mobile{
    width: 100%;
  }
  .rightTextBoxCase{
    flex-direction: column;
    gap: 0;
  }
  .HowWeStartedright{
    width: 75%;
    align-self: flex-end;
  }
  .GalleryBox{
    width: 100%;
  }
  .caseFirstImgBox{
    flex-direction: column;
  }
  .MaximumOfHeaderStyleOutout{
    font-size: 90px;
  }
}

@media only screen and (max-width: 450px) {
 
  .serviceBoxesHeader {
    font-size: 20px;
  }
  .MaximumOfHeaderStyle,
  .MaximumOfHeaderStyleItalic {
    font-size: 40px;
  }
  .DescriptionLittleTextStyle {
    font-size: 14px;
  }
  .ReachTheMaximumGetInTouch {
    font-size: 14px;
  }
  .getInTouchWordIn,
  .GetInTouchBox {
    font-size: 60px;
  }
  .MaximumOfHeaderStyleOutout{
    font-size: 90px;
  }

}
@media only screen and (max-width: 350px) {
  p,
  a {
    word-break: break-word;
  }
  .MaximumOfHeaderStyle,
  .MaximumOfHeaderStyleItalic {
    font-size: 30px;
  }

  .getInTouchWordIn,
  .GetInTouchBox {
    font-size: 50px;
  }
}
