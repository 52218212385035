
.worksContainersReverse,
.imgWorksBoxesSmallReverse {
  flex-direction: row-reverse;
}

.active {
  text-decoration: underline 1px;
}
.worksImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sucessTabDescription {
  white-space: pre-line;
}

.preloaderHeader {
  font-size: 4vw;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: white;
  width: 100%;
}
.preloaderline {
  height: 5px;
  background: white;
}
.MaximumOfDesignOfLinePreloader {
  align-items: center;
  gap: 10px;
  display: flex;
  width: 50%;
  justify-content: flex-end;
  flex: 1 1 0%;
}
.getInTouchBlock {
  height: auto;
  width: 100%;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}
.getTouchReachAndLine {
  align-items: center;
  display: flex;
  width: 50%;
  justify-content: flex-end;
  flex: 1 1;
}
.GetInTouchLine {
  margin-left: 10px;
  height: 3px;
  background: white;
}
.GetInTouchBox,
.ReachTheMaximumGetInTouch {
  display: inline-flex;
  align-items: flex-start;
  text-align: center;
  column-gap: 10px;
  justify-content: center;
  line-height: 1;
}
.GetInTouchBox {
  flex-wrap: wrap;
}
.ReachTheMaximumGetInTouch {
  width: 50%;
}
.GetInCOntainer {
  width: 80%;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.MaximumBlockHeader {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 200px;
}

.MaximumBlockHeaderBottomBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  /* gap: 10px; */
}
.serviceBoxesDescription {
  padding-left: 10px;
}
.MaximumBlackLine {
  height: 10px;
  background: black;
}
.MaximumBlockHeaderLineBox {
  align-items: center;
  /* gap: 10px; */
  display: flex;
  width: 50%;
  justify-content: flex-end;
  flex: 1 1 0%;
}
.MaximumOFContainer,
.preloaderTheMaximum {
  width: 50%;
}
.preloaderTheMaximum {
  text-align: start;
}
.wordReach {
  margin-right: 10px;
  font-style: italic;
}
.container {
  width: 100%;
  height: 100%;
}
.preLoader {
  position: fixed;
  z-index: 101;
  background-color: #141414;
  width: 100%;
  height: 100vh;
}
.timer {
  line-height: 1em;
  position: absolute;
  color: #fff;
  right: 0;
  bottom: 0;
  font-style: normal;
  font-weight: 350;
  font-size: calc(5vw + 5vh);
}
.centerLogoItems {
  position: absolute;
  width: 234px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
}
.centerLogoItemsStyle {
  background-color: #ffffff;
  width: 75px;
  height: 150px;
  opacity: 0;
}
.centerLogoLeftItem {
  transform: rotate(45deg);
}
.centerLogoRightItem {
  transform: rotate(-45deg);
}

.homePage {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  background-color: #e7ebee;
}

.bottomBoxGetInTouch,
.upperBoxGetInTouch,
.getInBox {
  display: flex;
}
.SecondAboutPageTextBlock {
  display: flex;
  justify-content: flex-end;
}
.SecondAboutPageTextContainer {
  display: flex;
  width: 50%;
  gap: 10px;
}
.SecondAboutPageLeftText,
.SecondAboutPageRightText {
  width: 50%;
}

.logoAndScrollUp {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 10;
}
.servicesGradientBlock,
.aboutGradientBlock,
.successGradientBlock,
.homeGradientBlock,
.teamGradientBlock,
.blackGradientBlock,
.violetGradientBlock,
.greenGradientBlock,
.teamGradientBlocReverse {
  height: 25vh;
  width: 100%;
}

.greenGradientBlock {
  background: linear-gradient(
    180deg,
    #59cf90 0%,
    #5acf91 6.67%,
    #5ed093 13.33%,
    #65d198 20%,
    #6ed39e 26.67%,
    #7ad5a6 33.33%,
    #88d8af 40%,
    #98dbba 46.67%,
    #a8dfc4 53.33%,
    #b8e2cf 60%,
    #c6e5d8 66.67%,
    #d2e7e0 73.33%,
    #dbe9e6 80%,
    #e2eaeb 86.67%,
    #e6ebed 93.33%,
    #e7ebee 100%
  );
}
.violetGradientBlock {
  background: linear-gradient(
    0deg,
    #e7ebee 0%,
    #e6eaee 6.67%,
    #e3e5ed 13.33%,
    #dfdeec 20%,
    #d8d4ea 26.67%,
    #d0c6e8 33.33%,
    #c6b6e6 40%,
    #bba5e3 46.67%,
    #b092e1 53.33%,
    #a581de 60%,
    #9b71dc 66.67%,
    #9363da 73.33%,
    #8c59d8 80%,
    #8852d7 86.67%,
    #854dd6 93.33%,
    #844cd6 100%
  );
}
.blackGradientBlock {
  background: linear-gradient(
    180deg,
    #141414 0%,
    #171717 6.67%,
    #1c1d1d 13.33%,
    #262627 20%,
    #343535 26.67%,
    #464748 33.33%,
    #5b5c5d 40%,
    #727476 46.67%,
    #8b8c8e 53.33%,
    #a2a4a7 60%,
    #b7b9bc 66.67%,
    #c9cbcf 73.33%,
    #d7dadd 80%,
    #e1e3e7 86.67%,
    #e6e9ed 93.33%,
    #e8ebef 100%
  );
}
.teamGradientBlock {
  background: linear-gradient(
    180deg,
    #5f64e0 0%,
    #6065e0 6.67%,
    #6469e0 13.33%,
    #6a6fe1 20%,
    #7378e2 26.67%,
    #7f83e3 33.33%,
    #8c91e5 40%,
    #9ba0e6 46.67%,
    #abafe8 53.33%,
    #babee9 60%,
    #c7cceb 66.67%,
    #d3d7ec 73.33%,
    #dce0ed 80%,
    #e2e6ee 86.67%,
    #e6eaee 93.33%,
    #e7ebee 100%
  );
}

.homeGradientBlock {
  opacity: 0;
  position: absolute;
  background: linear-gradient(
    180deg,
    #bba5e3 0%,
    #bca5e3 6.67%,
    #bda7e4 13.33%,
    #bfaae4 20%,
    #c2afe5 26.67%,
    #c6b5e5 33.33%,
    #cbbce6 40%,
    #d1c4e7 46.67%,
    #d6cce8 53.33%,
    #dcd4e9 60%,
    #e1dbea 66.67%,
    #e5e1eb 73.33%,
    #e8e6eb 80%,
    #eae9ec 86.67%,
    #ecebec 93.33%,
    #ececec 100%
  );
}
.successGradientBlock {
  background: linear-gradient(
    180deg,
    #6065e1 0%,
    #6165e0 6.67%,
    #6464dd 13.33%,
    #6a63d7 20%,
    #7261cf 26.67%,
    #7c5fc4 33.33%,
    #875cb8 40%,
    #9559aa 46.67%,
    #a2579c 53.33%,
    #b0548e 60%,
    #bb5182 66.67%,
    #c54f77 73.33%,
    #cd4d6f 80%,
    #d34c69 86.67%,
    #d64b66 93.33%,
    #d74b65 100%
  );
}
.aboutGradientBlock {
  background: linear-gradient(
    180deg,
    #e7ebee 0%,
    #e6eaee 6.67%,
    #e3e5ed 13.33%,
    #dfdeec 20%,
    #d8d4ea 26.67%,
    #d0c6e8 33.33%,
    #c6b6e6 40%,
    #bba5e3 46.67%,
    #b092e1 53.33%,
    #a581de 60%,
    #9b71dc 66.67%,
    #9363da 73.33%,
    #8c59d8 80%,
    #8852d7 86.67%,
    #854dd6 93.33%,
    #844cd6 100%
  );
}
.servicesGradientBlock {
  background: linear-gradient(
    180deg,
    #d74b65 0%,
    #d74c66 6.67%,
    #d8516a 13.33%,
    #d85870 20%,
    #d96379 26.67%,
    #db7085 33.33%,
    #dc8092 40%,
    #de92a2 46.67%,
    #e0a4b1 53.33%,
    #e2b6c1 60%,
    #e3c6ce 66.67%,
    #e5d3da 73.33%,
    #e6dee3 80%,
    #e6e5e9 86.67%,
    #e7eaed 93.33%,
    #e7ebee 100%
  );
}
.headerMenu {
  display: flex;
  flex-direction: column;
}

.logoHeader {
  width: calc(3vw + 3vh);
}
.homeLogo {
  width: 63px;
  height: 31.5px;
}

.videoBack {
  display: block;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  /* -webkit-clip-path: polygon(
    0% 0%,
    0% 70.5%,
    17% 97%,
    50% 41.5%,
    84% 97%,
    100% 70.5%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 70.5%,
    17% 97%,
    50% 41.5%,
    84% 97%,
    100% 70.5%,
    100% 0%
  ); */
}

.imgBackGradientRightItem,
.imgBackGradientLeftItem {
  width: 100%;
  height: 100%;
  opacity: 0;
  
}

.backSvg {
  position: absolute;
  transform: translateY(100%);
  filter: blur(150px);
}

.aboutBlock {
  background: #844dd6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.aboutTextBlock {
  display: flex;
}

.aboutText,
.aboutTextBoxes {
  width: 50%;
}
.aboutTextBlockMain {
  margin-bottom: 200px;
}
.aboutWord,
.leftServiceBox {
  box-sizing: border-box;
  padding-left: 10px;
  width: 50%;
  text-align: left;
}

.videoBlock {
  background: #bba5e3;
  position: relative;
  width: 100%;
  height: 100vh;
}
.backVideoBlock {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: auto;
}
.logoMask {
  position: absolute;
  height: 100vh;
  width: 100%;
  bottom: 0;
  top: auto;
}

.worksContainers {
  width: 50%;
  display: flex;
}
.worksBigContainers {
  display: flex;
  width: 100%;
}
.worksImgFirst {
  width: 50%;
}
.worksImgSecond {
  width: 100%;
}
.worksBoxes {
  width: 50%;
}
.worksBoxesTextBlok,
.worksBigBlockText {
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.AboutDescription {
  margin-bottom: 20px;
}

.worksMainContainers {
  display: flex;
  justify-content: flex-end;
  margin-top: 200px;
}

.secondWorksMainContainers,
.worksMainContainersleft {
  justify-content: flex-start;
}

.worksBigBackgroundBlock {
  display: flex;
  justify-content: center;
  width: 100%;
}
.worksBigBackgroundContainer {
  text-align: center;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.worksBigImg {
  width: 100%;
  /* height: 90vh;
  object-fit: cover; */
}

.worksBigBlockText {
  justify-content: space-around;
  width: 100%;
}

.worksBlock {
  position: relative;
  padding-top: 50px;
}
.servicesBlock {
  background: #d74b65;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.serviceMainContainer,
.successMainContainer {
  display: flex;
  width: 100%;
}
.successMainContainer,
.aboutBoxesDescription {
  margin-top: 200px;
}
.aboutPageMarginBlock {
  width: 100%;
  height: 200px;
}
.rightServiceBox,
.serviceBoxesHeader,
.serviceBoxesDescription,
.leftSuccessBox,
.rightSuccessBox {
  width: 50%;
}
.serviceBoxesDescription {
  display: flex;
  flex-direction: column;
}
.rightServiceBox {
  box-sizing: border-box;
  padding: 5px;
}

.serviceSmallBoxes {
  display: flex;
  margin-bottom: 32px;
}

.sucessTabDescription {
  width: 75%;
}
.rightSuccessBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  padding-right: 10px;
}
.successBlock {
  background: #6065e1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* height: 100vh; */
}
.successBoxImg {
  width: 20%;
  align-self: center;
  z-index: 1;
}
.leftSuccessBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.successImg {
  height: 150px;
  width: 100%;
}
.galleryBlock {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: flex-end;
}
.blocksBackgroundColorBlack {
  background-color: #141414;
  display: flex;
  flex-direction: column;
}
.blocksBackgroundColorViolet {
  background-color: #844dd6;
}
.GalleryBox {
  position: relative;
  width: 50%;
}
.galleryImg {
  width: 100%;
  display: block;
  /* object-fit: cover; */
  height: auto;
  margin: 0;
}
.galleryLoader {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 100%;
  right: 50%;
}
.rightGalleryBox {
  position: relative;
}
.galleryLoaderItem,
.worksLoaderItem,
.worksBigImageLoaderItem {
  width: 25%;
  height: 90%;
  background-color: white;
}
.galleryLoaderLeftItem,
.worksLoaderLeftItem,
.worksBigImageLoaderLeftItem {
  transform: rotate(45deg);
}
.galleryLoaderRightItem,
.worksLoaderRightItem,
.worksBigImageLoaderRightItem {
  transform: rotate(-45deg);
}
.teamGradientBlocReverse {
  background: linear-gradient(
    0deg,
    #5f64e0 0%,
    #6065e0 6.67%,
    #6469e0 13.33%,
    #6a6fe1 20%,
    #7378e2 26.67%,
    #7f83e3 33.33%,
    #8c91e5 40%,
    #9ba0e6 46.67%,
    #abafe8 53.33%,
    #babee9 60%,
    #c7cceb 66.67%,
    #d3d7ec 73.33%,
    #dce0ed 80%,
    #e2e6ee 86.67%,
    #e6eaee 93.33%,
    #e7ebee 100%
  );
}
.centerContainerGetInTouch {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  position: absolute;
  height: 25vh;
  justify-content: space-around;
}
.bottomBoxGetInTouch {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.getInTouchWordGet,
.getInTouchWordIn,
.getInTouchWordTouch {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.getInTouchWordGet,
.getInTouchWordIn {
  background-image: linear-gradient(to right, rgb(251 37 37), rgb(225 46 204));
}
.getInTouchWordTouch {
  background-image: linear-gradient(
    45deg,
    rgb(90 96 253) 25%,
    rgb(149 81 196) 50%,
    white 75%
  );
}

.footerBlock,
.footerBlockMobile {
  color: white;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerConteiner {
  width: 99%;
  display: flex;
  height: auto;
  margin-left: 10px;
}
.leftFooterBox,
.footerContacts,
.footerContactInfo,
.footerContactId {
  width: 50%;
}
.rightFooterBox {
  width: 49%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(67, 67, 67, 0.5);
}
.rightUpperBoxFooter,
.rightBottomBoxFooter {
  display: flex;
  justify-content: space-between;
}
.logoFooterBox,
.footerDescription,
.footerSocialNetworks {
  width: 25%;
}
.footer2020 {
  border-bottom: 1px solid rgba(67, 67, 67, 0.5);
  width: 99%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftFooterBox {
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.rightUpperBoxFooter {
  border-bottom: 1px solid rgba(67, 67, 67, 0.5);
  height: auto;
}
.footerContactsBoxes {
  display: flex;
}
.footerContacts {
  opacity: 0.5;
  display: flex;
  flex-direction: column;
}
.footerDescription {
  display: flex;
  align-items: center;
}
a {
  text-decoration: none;
}
a,
a:hover,
a:focus,
a:active {
  color: inherit;
}
.textHover:hover,
.footerContactHeader:hover,
.footeMobileSocialItem:hover {
  color: #5f64e0;
}
.textOpacity,
.footerDescription,
.footer2020 {
  opacity: 0.5;
}
.logoFooterBox,
.footerContactsBoxes {
  box-sizing: border-box;
  padding: 10px;
}

.footerSocialNetworks {
  margin-top: 10px;
}

.rightBottomBoxFooter {
  margin-top: 20px;
}
.footerContactHeader {
  margin-bottom: 8px;
}
.logoFooter {
  width: 100%;
}

.worksImgHover {
  -webkit-clip-path: inset(0 0 100% 0);
  clip-path: inset(0 0 100% 0);
}
.worksLoaderRightItem,
.worksLoaderLeftItem,
.worksBigImageLoaderLeftItem,
.worksBigImageLoaderRightItem {
  -webkit-clip-path: inset(100% 0 0 0);
  clip-path: inset(100% 0 0 0);
}
.imgWorksBox {
  width: 100%;
  position: relative;
  display: flex;
}
.worksSecondImgHover {
  position: absolute;
  width: 50%;
  right: 0;
  height: 100%;
}
.successTabs {
  margin-bottom: 25px;
}
.shapeSucess {
  position: absolute;
  background-color: white;
  width: 25%;
  height: 80%;
  z-index: 0;
  transform: rotate(135deg);
  overflow: hidden;
}
.sucessHeaderBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrovShape {
  width: 0;
  height: 0;
  border-right: 1.25vh solid transparent;
  border-left: 1.25vh solid transparent;
  border-top: 1.75vh solid rgb(0, 0, 0);
}
.homeLogobox,
.mobileActiveBurgerLogo {
  width: 50%;
  padding-left: 10px;
}
.rightBoxHeader {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.headerGetInTouch {
  width: 25%;
}
.burgerIcon {
  display: none;
  cursor: pointer;
}
.worksBoxes,
.worksBigBackgroundContainer {
  cursor: pointer;
}

.MaximumBlockHeaderTOMAX {
  margin-bottom: 200px;
}

.mobileBurgerMenuBlock {
  background-color: #141414;
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  z-index: 100;
}
.mobileBurgerCenterMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.mobileBottomMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  width: 100%;
  bottom: 0;
}
.mobileActiveBurgerIcon {
  padding: 10px;
  cursor: pointer;
}

.worksHoverLoader {
  transform: rotate(90deg);
  position: absolute;
  display: flex;
  /* bottom: 0; */
  top: 30%;
  width: 40%;
  height: 40%;
  left: 5%;
  justify-content: space-around;
  align-items: center;
}
.worksBigImageHoverLoader {
  z-index: 1;
  transform: rotate(90deg);
  position: absolute;
  display: flex;
  width: 50%;
  height: 50%;
  top: 25%;
  justify-content: space-around;
  align-items: center;
}
.worksBigImageHoverBox {
  right: 0;
  height: 100%;
  position: absolute;
  width: 50%;
}
.worksBigImgHover {
  width: 100%;
  height: 100%;
  -webkit-clip-path: inset(0 0 100% 0);
  clip-path: inset(0 0 100% 0);
}
.getInBlock {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}
.footerBlockMobile {
  display: none;
}
.homeGradientBlockAboutPage {
  position: inherit;
}
.worksMainContainersMarginTop {
  margin-top: 0;
}
.NavigationMenuBigTextBlack {
  color: #141414;
}
.ThirdAboutTextBlock,
.FeedBackTextBlock {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  gap: 70px;
  text-align: center;
  padding: 0px 20px 0px 20px;
}
.ThirdAboutTextBlock {
  background-color: #5f64e0;
}

.valueContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 200px;
}
.valueTextBox {
  display: inline-flex;
  flex-direction: column;
  display: flex;
  gap: 80px;
  width: 50%;
}
.mainBlockCase {
  position: relative;
  /* background-image: url(/public/img/case/backMainCase.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  width: 100%;
  height: 100vh;
  color: white;
}
.caseScrollLogo {
  top: 15%;
}
.mainBlockCase {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.caseMainTitle {
  width: 90%;
  flex: 1;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  /* word-wrap: break-word;
  overflow-wrap: break-word; */
}
.TextBlockCase {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 200px;
  width: 100%;
}

.CaseContainer {
  display: flex;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.BlockHeaderCase,
.CaseDescriptionBigTextStyle {
  width: 50%;
}
.marginBlockPortfolio {
  height: 200px;
  width: 100%;
}
.rightTextBlockCase {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.rightTextBoxCase {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}
.box50 {
  width: 50%;
}
.caseFirstImgBox {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.projectInfoCase,
.HowWeStarted,
.AboutBlockCase {
  margin-top: 200px;
}

.AboutBlockCaseImg,
.GalleryCaseImg {
  width: 100%;
}

.MaxOutput {
  box-sizing: border-box;
  padding: 15px;
  background-color: #58ce8f;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.OutputConteiner {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}
.OutputBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.OutputConteinerSize {
  margin-top: 200px;
  width: 25%;
}

.MainBlockHeaderFirstWord,
.MaximumBlockHeaderLineBox,
.MainBlockHeaderThirdWord,
.logoAndScrollUp,
.header {
  opacity: 0;
}

.MaximumBlockHeaderMain {
  margin-top: 0;
}
.HeaderGetinTouch,
.headerMenu {
  width: 25%;
}
.DescriptionWorks {
  text-align: start;
}
h4 {
  font-weight: 400;
}

.shortPreloader {
  width: 100%;
  height: 100vh;
  background-color: #141414;
  z-index: 100;
  position: fixed;
}
.preloadLoad {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 15vh;
}
