@font-face {
  font-family: "ABCFavorit-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-Bold-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-BoldItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-BoldItalic-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-Book";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-Book-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-BookItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-BookItalic-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-Light";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-Light-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-LightItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-LightItalic-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-Medium-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-MediumItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-MediumItalic-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-Regular-Trial.otf")
    format("opentype");
}
@font-face {
  font-family: "ABCFavorit-RegularItalic";
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/abc-favorit-font/ABCFavorit-RegularItalic-Trial.otf")
    format("opentype");
}

* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  /* overflow: hidden; */
  /* visibility: hidden; */
  width: 100%;
  background-color: #e7ebee;
  font-family: "ABCFavorit-Light";
}
.MaximumOfHeaderStyle {
  /* h2 */
  font-family: "ABCFavorit-Light";
  font-size: 160px;
  font-style: normal;
  font-weight: 600;
  line-height: 87.5%;
}
.MaximumOfHeaderStyleItalic {
  /* h2 */
  padding-right: 20px;
  font-style: italic;
}
.DescriptionNormalTextStyle {
  font-family: "ABCFavorit-Book";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.DescriptionLittleTextStyle {
  font-family: "ABCFavorit-Book";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
.DescriptionBigTextStyle {
  font-family: "ABCFavorit-Book";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
}
.BlockHeaderItalic {
  /* Italic Title ✦ H4 */
  font-family: "ABCFavorit-BookItalic";
  font-size: 50px;
  font-style: italic;
  font-weight: 350;
  line-height: 100%;
}
.BlockHeader {
  font-family: "ABCFavorit-Book";
  font-size: 50px;
  font-style: normal;
  font-weight: 350;
  line-height: 100%;
}
.NavigationMenuBigText {
  color: #141414;
  /* Title ✦ H3 */
  letter-spacing: -3.5px;
  font-family: "ABCFavorit-Light";
  font-size: 90px;
  font-style: normal;
  font-weight: 350;
  line-height: 100%;
}
